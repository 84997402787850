import React, {useMemo, useState} from 'react';
import {Grid} from '@material-ui/core';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import Radio from '../Radio/Radio';
import DragDropDataTable from '../DataTable/DragDropDataTable';
import {CallForwardingRule} from '../Forms/Extensions/CallForwarding/CallForwardingForm.utils';
import CreationButton from '../CreationButton/CreationButton';
import {useSelector} from 'react-redux';
import {ReduxState} from '../../store/types';
import AlertDialog from '../AlertDialog/AlertDialog';
import {DialogButton} from '../AlertDialog/DialogContainer';
import {generateColumns, IntervalStatus, IntervalType, useStyles} from "./IntervalSelect.utils";
import classNames from "classnames";

type IntervalSelectProps = {
    withoutYears?: boolean;
    defaultIntervalType?: IntervalType;
};

const IntervalSelect: React.VFC<IntervalSelectProps> = (
    {
        withoutYears
    }) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const [intervalToDeleteIndex, setIntervalToDeleteIndex] = useState<number | undefined>(undefined);

    const outTimeFormat = useSelector<ReduxState, string | undefined>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info?.out_time_format,
    );

    const confirmDelete = (indexToRemove?: number) => {
        if (indexToRemove !== undefined) {
            setIntervalToDeleteIndex(indexToRemove);
        }
    };

    const deleteInterval = () => {
        if (intervalToDeleteIndex !== undefined) {
            setFieldValue(
                'intervals.intervals',
                values.intervals?.intervals.filter(
                    (_, index) => index !== intervalToDeleteIndex,
                ),
                false,
            );
            setIntervalToDeleteIndex(undefined);
        }
    };

    const addInterval = () => {
        setFieldValue(
            'intervals.intervals',
            [
                ...(values.intervals?.intervals || []),
                {
                    startTime: '00:00',
                    endTime: '23:59',
                    daysOfMonth: '',
                    days: [],
                    months: [],
                    years: [],
                    wholeDay: false,
                    type: 'new',
                },
            ],
            false,
        );
    };

    const {
        values,
        setFieldValue,
        errors,
        handleChange,
        setFieldError,
        initialValues
    } = useFormikContext<CallForwardingRule>();

    const columns = useMemo(
        () =>
            generateColumns(
                t,
                classes,
                setFieldValue,
                confirmDelete,
                errors,
                handleChange,
                setFieldError,
                withoutYears,
                outTimeFormat?.includes('AM')
            ),
        [
            errors,
            values.intervals?.intervals,
            withoutYears,
            outTimeFormat,
            values.intervals?.activity
        ],
    );

    return (
        <div id={'abcd-abcd'}>
            <Grid item className={classes.itemsContainer}>
                <p className={classes.activeHeader}>
                    {t('screens:addToRingGroup.active')}
                </p>

                <Grid>
                    <Radio
                        dataQa="status-always"
                        checked={
                            values.intervals?.activity === IntervalStatus.Always
                        }
                        name="activity"
                        value={values.intervals?.activity}
                        onChange={() => {
                            setFieldValue('intervals', {
                                activity: IntervalStatus.Always,
                                intervals: []
                            })
                        }}
                        label={t('screens:ringSchedule.always')}
                    />

                    <Radio
                        dataQa="status-following-intervals"
                        checked={
                            values.intervals?.activity ===
                            IntervalStatus.OnlyFollowingTimeInterval
                        }
                        name="activity"
                        value={values.intervals?.activity}
                        onChange={() => {
                            setFieldValue('intervals', {
                                activity: IntervalStatus.OnlyFollowingTimeInterval,
                                intervals: initialValues.intervals?.intervals
                            })
                        }
                        }
                        label={t('screens:ringSchedule.onlyFollowingTime')}
                        className={classes.secondRadio}
                    />
                </Grid>

                {values.intervals?.activity ===
                    IntervalStatus.OnlyFollowingTimeInterval && (
                        <DragDropDataTable
                            className={
                                classNames(
                                    classes.table,
                                    values.intervals?.intervals?.length == 1 ? classes.intervals : ''
                                )
                            }
                            classes={{pagination: classes.pagination, rootTable: classes.rootTable}}
                            rows={values.intervals?.intervals}
                            columns={columns}
                            rowCount={values.intervals?.intervals?.length}
                            dragEnable={false}
                            hidePagination
                        />
                    )}
            </Grid>

            {values.intervals?.activity ===
                IntervalStatus.OnlyFollowingTimeInterval && (
                    <CreationButton
                        title={t('screens:ringSchedule.addInterval')}
                        onClick={addInterval}
                        className={classNames(classes.addInterval, values.intervals?.intervals?.length == 1 ? classes.noTopMargin : '')}
                    />
                )}

            <AlertDialog
                isOpen={intervalToDeleteIndex !== undefined}
                description={t('screens:extensions.deleteTimeInterval')}
                contentClass="alert-content"
                className={classes.removeDialogContainer}
                hideHeader={true}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() => setIntervalToDeleteIndex(undefined)}
                    />,
                    <DialogButton
                        key="exlude"
                        label={t('common:delete')}
                        onClick={deleteInterval}
                    />,
                ]}
                dataQa="remove-interval-modal"
                dataTestId="remove-interval-modal"
            />
        </div>
    );
};

export default IntervalSelect;
