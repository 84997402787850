import {TFunction} from "react-i18next";
import React from "react";
import {Columns} from "@material-ui/data-grid";
import {FormikErrors} from "formik";
import {Interval} from "../../store/types/RingGroup";
import TimeRangePicker from "../TimeRangePicker/TimeRangePicker";
import {convertRangeToStartEndTime, prepareDefaultRangeDate} from "../TimeRangePicker/TimeRangePicker.utils";
import {Box, makeStyles} from "@material-ui/core";
import classNames from "classnames";
import TextField from "../TextField/TextField";
import SelectField from "../SelectField/SelectField";
import {
    WeekDayDescriptor,
    daysSelectItems,
    monthsSelectItems,
    validateDayOfMonth,
    yearsSelectItems
} from "../../utils/extensions/RingScheduleSummary";
import CustomizedIconButton from "../IconButton/IconButton";
import * as Yup from "yup";
import i18n from "../../services/i18n";
import OverflowTooltip from "../OverflowTooltip/OverflowTooltip";
import {Delete} from "@material-ui/icons";
import {Colors} from "../../styles/Colors";

const DeleteIcon = <Delete htmlColor={Colors.Gray5}/>;
type SelectItem = {
    name: string;
    value: number;
    apiValue: string;
};

export enum IntervalStatus {
    Always = '0',
    OnlyFollowingTimeInterval = '1',
}

export interface IntervalFormProps {
    activity: IntervalStatus;
    intervals: Interval[];
    timeFilterIndex?: number;
}

export const intervalFormInitialValues: IntervalFormProps = {
    activity: IntervalStatus.Always,
    intervals: [],
};
const incorrectFormat = i18n.t<string>('errors:extensions.incorrectFormat');
export const intervalFormValidationSchema = Yup.object().shape({
    intervals: Yup.object().nullable().shape({
        activity: Yup.string().notRequired(),
        intervals: Yup.array()
            .of(
                Yup.object().shape({
                    startTime: Yup.string().length(5, incorrectFormat),
                    endTime: Yup.string().length(5, incorrectFormat),
                    daysOfMonth: Yup.string()
                        .test(validateDayOfMonth)
                        .notRequired(),
                    days: Yup.array().notRequired(),
                    months: Yup.array().notRequired(),
                }),
            )
            .notRequired(),
        })
});
export const useStyles = makeStyles(() => ({
    itemsContainer: {
        display: 'flex',
        flexDirection: 'column',

        '& .MuiFormControl-root': {
            flex: 1,
        },

        '& > :first-child': {
            marginTop: 0,
        },

        '& .MuiInputLabel-root': {
            marginLeft: 10,
            zIndex: 2,
        },
        '& input[type="time"]::-webkit-calendar-picker-indicator': {
            display: 'none',
            '-webkit-appearance': 'none',
        },

        '& .MuiTableContainer-root': {
            position: 'inherit',
        },
    },
    visibilityIcon: {
        marginRight: 6,
    },
    primaryActionButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    header: {
        '& .MuiTypography-h6': {
            fontWeight: 'bold',
            fontSize: 18,
        },
    },
    modalContainer: {
        '& .MuiDialogContent-root': {
            width: 1200,
            maxHeight: '60vh',
        },
    },
    groupSelect: {
        maxWidth: 392,
    },
    activeHeader: {
        fontWeight: 'bold',
        color: Colors.Gray7,
        marginBlockEnd: '0',
    },
    table: {
        '& .MuiTableCell-root': {
            padding: 2,
            paddingTop: 3,
            paddingBottom: 5,
            alignItems: 'flex-start',
            display: 'flex',
        },
        '& .MuiFormControl-root:first-of-type': {
            marginRight: '10px !important',
        },
        '& .MuiFormControl-root': {
            marginRight: '10px !important',
        },
    },
    pagination: {
        visibility: 'hidden',
        '& .MuiInputBase-input:first-of-type': {
            padding: '0 !important',
        },
    },
    rootTable: {
        '&.MuiTableContainer-root': {
            overflowX:'unset'
        }
    },
    actionButtons: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: 58,
    },
    daysOfMonthContainer: {
        display: 'inline-flex',
        flexDirection: 'column',
        padding: '0 !important',
        minWidth: 190,
        '& .MuiFormHelperText-root': {
            marginTop: 0,
        },
        flex: 1,
    },
    daysOfMonthHint: {
        color: Colors.PlaceHolder,
        lineHeight: '12px',
        fontSize: 12,
        marginTop: 4,
        marginLeft: 15,
    },
    daysMonthsSelect: {
        display: 'flex',

        '& .MuiInputBase-root': {
            paddingBottom: 5,
            height: 'auto',
            paddingLeft: 10,
        },
    },

    selectWholeDayText: {
        fontSize: 12,
        marginRight: 20,
    },
    addInterval: {
        marginTop: 20,
        '& .MuiButton-label': {
            color: Colors.White + '!important',
            paddingLeft: '0!important',
            paddingRight: '0!important',
        },
    },
    secondRadio: {
        marginLeft: 20,
    },
    intervals: {minHeight: 110},
    noTopMargin: {marginTop:0},
    selectContainer: {
        minWidth: 'unset',
        flex: 1,

        '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input': {
            padding: '0!important',
            flexGrow: 'unset',
            minWidth: 'unset',
        },
    },
    removeDialogContainer: {
        '& .MuiDialogContent-root': {
            width: '325px !important',
            minWidth: '325px !important',
            backgroundColor: Colors.White,
        },

    },
}));

const CustomTags = (selected: string[]) => {
    //@ts-ignore - material ui lib bug
    const renderTagsValue = (selected ?? []).map((v) => v.name).join(', ');
    return (
        <div
            style={{
                flex: 1,
                marginLeft: 5,
                width: 53,
            }}
        >
            <OverflowTooltip
                text={renderTagsValue}
                tooltip={renderTagsValue}
                copy={false}
            />
        </div>
    );
};

export const generateColumns = (
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    setFieldValue: (fieldName: string, value: any, validate?: boolean) => void,
    deleteItem: (indexToRemove?: number) => void,
    errors: any,
    handleChange: (e: React.ChangeEvent<any>) => void,
    setFieldError: (field: string, value: string | undefined) => void,
    withoutYears?: boolean,
    ampm?: boolean
): Columns => {
    return [
        {
            field: 'startTime',
            width: 220,
            renderCell: function IdText(params) {
                const helperErrors = errors?.intervals?.intervals?.[
                    params.rowIndex || 0] as FormikErrors<Interval>;

                return (
                    <TimeRangePicker
                        label={t('screens:extensions.time')}
                        ampm={ampm}
                        defaultValue={prepareDefaultRangeDate(
                            params.row.startTime,
                            params.row.endTime,
                            ampm,
                        )}
                        onChange={(value) => {
                            const result = convertRangeToStartEndTime(
                                value,
                                ampm,
                            );
                            setFieldValue(
                                `intervals.intervals.[${params.rowIndex}].[startTime]`,
                                result.startTime,
                                false,
                            );
                            setFieldValue(
                                `intervals.intervals.[${params.rowIndex}].[endTime]`,
                                result.endTime,
                                false,
                            );
                            setFieldError(
                                `intervals.intervals.[${params.rowIndex}].[startTime]`,
                                undefined,
                            );
                            setFieldError(
                                `intervals.intervals.[${params.rowIndex}].[endTime]`,
                                undefined,
                            );
                        }}
                        helperText={
                            helperErrors?.startTime || helperErrors?.endTime
                        }
                    />
                );
            },
        },
        {
            field: 'daysOfMonth',
            flex: 0.8,
            renderCell: function IdText(params) {
                const helperText = (errors?.intervals?.intervals?.[
                params.rowIndex || 0
                    ] as FormikErrors<Interval>)?.daysOfMonth
                    ? t('errors:extensions.daysOfTheMonth')
                    : '';

                return (
                    <Box className={classNames(classes.daysOfMonthContainer)}>
                        <TextField
                            id={`intervals.intervals.[${params.rowIndex}].[daysOfMonth]`}
                            onChange={handleChange}
                            label={t('screens:ringSchedule.daysOfTheMonth')}
                            value={params.row.daysOfMonth}
                            helperText={helperText}
                            setFieldError={setFieldError}
                        />

                        <span className={classes.daysOfMonthHint}>
                            {!helperText && t('screens:extensions.example')}
                        </span>
                    </Box>
                );
            },
        },
        {
            field: 'days',
            flex: 0.7,
            renderCell: function IdText(params) {
                return (
                    <SelectField
                        label={t('screens:ringSchedule.days')}
                        items={daysSelectItems}
                        value={
                            daysSelectItems.filter((v) =>
                                (params.row.days ?? [])
                                    .map((v: SelectItem) => v.value)
                                    .includes(v.value),
                            ) || null
                        }
                        multiple
                        onChange={(_, value) => {
                            setFieldValue(
                                `intervals.intervals.[${params.rowIndex}].[days]`,
                                value,
                            );
                        }}
                        renderTags={CustomTags}
                        className={classes.daysMonthsSelect}
                        getOptionLabel={(v: SelectItem) => v.name}
                        getOptionSelected={(
                            option: SelectItem,
                            value: SelectItem,
                        ) => value.value === option.value}
                        classes={{
                            container: classes.selectContainer,
                        }}
                    />
                );
            },
        },
        {
            field: 'months',
            flex: 0.7,
            renderCell: function IdText(params) {
                return (
                    <SelectField
                        label={t('screens:ringSchedule.months')}
                        items={monthsSelectItems}
                        value={
                            monthsSelectItems.filter((v) =>
                                (params.row.months ?? [])
                                    .map((v: SelectItem) => v.value)
                                    .includes(v.value),
                            ) || null
                        }
                        multiple
                        onChange={(_, value) => {
                            setFieldValue(
                                `intervals.intervals.[${params.rowIndex}].[months]`,
                                value,
                            );
                        }}
                        renderTags={CustomTags}
                        className={classes.daysMonthsSelect}
                        getOptionLabel={(v: SelectItem) => v.name}
                        getOptionSelected={(
                            option: SelectItem,
                            value: SelectItem,
                        ) => value.value === option.value}
                        classes={{
                            container: classes.selectContainer,
                        }}
                    />
                );
            },
        },
        !withoutYears
            ? {
                field: 'years',
                flex: 0.7,
                renderCell: function IdText(params) {
                    const yearsList = [...yearsSelectItems];
                    const missingItems: string[] = (params.row.years ?? [])
                        .map((v: SelectItem) => v.apiValue)
                        .filter((y: number) => !yearsList.find(e => e.apiValue.toString() === y.toString()));
                    for(const year of missingItems) {
                        const positionItems1 = yearsList
                            .map((e, indx) => {
                                return {
                                    value: parseInt(e.apiValue) - parseInt(year),
                                    index: indx
                                };
                            });
                        const positionItems2 = positionItems1
                            .filter(e => e.value > 0);
                            positionItems2.sort((a, b) => a.value - b.value);
                        const position = positionItems2.length > 0 ? positionItems2[0].index : 0;
                        const obj: WeekDayDescriptor = {
                            name: year,
                            value: parseInt(year),
                            apiValue: year
                        };
                        yearsList.splice(position, 0, obj);
                    }

                    const selectedValues = 
                        yearsList.filter((v) =>
                            (params.row.years ?? [])
                                .map((v: SelectItem) => v.value)
                                .includes(v.value),
                        ) || null;

                    return (
                        <SelectField
                            label={t('screens:ringSchedule.years')}
                            items={yearsList}
                            value={selectedValues}
                            multiple
                            onChange={(_, value) => {
                                setFieldValue(
                                    `intervals.intervals.[${params.rowIndex}].[years]`,
                                    value,
                                );
                            }}
                            renderTags={CustomTags}
                            className={classes.daysMonthsSelect}
                            getOptionLabel={(v: SelectItem) => v.name}
                            getOptionSelected={(
                                option: SelectItem,
                                value: SelectItem,
                            ) => value.value === option.value}
                            classes={{
                                container: classes.selectContainer,
                            }}
                        />
                    );
                },
            }
            : {
                field: 'years',
                width: 0,
            },
        {
            field: 'actions',
            width: 50,
            renderCell: function IdText(params) {
                return (
                    <div className={classes.actionButtons}>
                        <CustomizedIconButton
                            onClick={() =>
                                deleteItem(params.rowIndex)
                            }
                            dataTestId="remove-interval-button"
                            tooltipText={t('common:delete')}
                        >
                            {DeleteIcon}
                        </CustomizedIconButton>
                    </div>
                );
            },
        },
    ];
};

export type IntervalType = 'old' | 'new';