import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from '../../actions/faxMailboxes';
import { FetchDataStatus } from '../autoAttendants/reducer';
import { AutoAttendantListItem } from '../../types/AutoAttendant';
import { APIErrorInterface } from '../../types';
import { YesNo } from '../../types/CallScreening';

export interface FaxMailboxesListType {
    items: AutoAttendantListItem[];
    total: number;
    isLoading: boolean;
    allTotal: number;
}

export interface FaxMailboxesStateType {
    faxMailboxesList?: FaxMailboxesListType;
    isCreateNewFaxMailboxes?: boolean;
    createNewFaxMailboxesErrors?: APIErrorInterface;
    isLoadingDetails?: boolean;
    isFormSending?: boolean;
    deletingInProgress?: boolean;
    apiError?: APIErrorInterface
}

export const initialState: FaxMailboxesStateType = {};

export type FaxMailboxesActionsType = ActionType<typeof actions>;

const faxMailboxesReducer = createReducer<
    FaxMailboxesStateType,
    FaxMailboxesActionsType
>(initialState)
    .handleAction(actions.getFaxMailboxesList.request, (state) => ({
        ...state,
        faxMailboxesList: {
            items:  [],
            total:  0,
            isLoading: true,
            allTotal: 0,
        },
        apiError: undefined
    }))
    .handleAction(actions.getFaxMailboxesList.success, (state, action) => ({
        ...state,
        faxMailboxesList: {
            items: action.payload.items,
            total: action.payload.total,
            isLoading: false,
            allTotal: action.payload.total,
        },
    }))
    .handleAction(actions.getFaxMailboxesList.failure, (state) => ({
        ...state,
        faxMailboxesList: {
            items: state.faxMailboxesList?.items || [],
            total: state.faxMailboxesList?.total || 0,
            isLoading: false,
            allTotal: state.faxMailboxesList?.allTotal || 0,
        },
    }))
    .handleAction(actions.fetchDIDNumber.request, (state, action) => {
        return {
            ...state,
            faxMailboxesList: {
                ...state.faxMailboxesList,
                items:
                    state.faxMailboxesList?.items.map((o) => {
                        if (o.i_account == action.payload.i_account) {
                            return {
                                ...o,
                                fetchDIDNumbers: FetchDataStatus.InProgress,
                            };
                        }
                        return o;
                    }) || [],
                total: state.faxMailboxesList?.total || 0,
                isLoading: state.faxMailboxesList?.isLoading || false,
                allTotal: state.faxMailboxesList?.allTotal || 0,
            },
        };
    })
    .handleAction(actions.fetchDIDNumber.success, (state, action) => {
        return {
            ...state,
            faxMailboxesList: {
                ...state.faxMailboxesList,
                items:
                    state.faxMailboxesList?.items.map((o) => {
                        if (o.i_account == action.payload.i_account) {
                            return {
                                ...o,
                                numbers: o.numbers && [
                                    ...new Set([
                                        ...o.numbers,
                                        ...action.payload.numbers.map(
                                            (n) => n.did_number,
                                        ),
                                    ]),
                                ],
                                fetchDIDNumbers: FetchDataStatus.Done,
                            };
                        }
                        return o;
                    }) || [],
                total: state.faxMailboxesList?.total || 0,
                isLoading: state.faxMailboxesList?.isLoading || false,
                allTotal: state.faxMailboxesList?.allTotal || 0,
            },
        };
    })
    .handleAction(actions.fetchDIDNumber.failure, (state, action) => {
        return {
            ...state,
            faxMailboxesList: {
                ...state.faxMailboxesList,
                items:
                    state.faxMailboxesList?.items.map((o) => {
                        if (o.i_account == action.payload.i_account) {
                            return {
                                ...o,
                                fetchDIDNumbers: FetchDataStatus.Done,
                            };
                        }
                        return o;
                    }) || [],
                total: state.faxMailboxesList?.total || 0,
                isLoading: state.faxMailboxesList?.isLoading || false,
                allTotal: state.faxMailboxesList?.allTotal || 0,
            },
        };
    })
    .handleAction(actions.createNewFaxMailbox.request, (state) => ({
        ...state,
        isCreateNewFaxMailboxes: true,
        createNewFaxMailboxesErrors: undefined,
    }))
    .handleAction(actions.createNewFaxMailbox.success, (state) => ({
        ...state,
        isCreateNewFaxMailboxes: false,
        createNewFaxMailboxesErrors: undefined,
    }))
    .handleAction(actions.createNewFaxMailbox.failure, (state, action) => ({
        ...state,
        isCreateNewFaxMailboxes: false,
        createNewFaxMailboxesErrors: action.payload,
    }))
    .handleAction(actions.getFaxMailboxesDetails.request, (state) => ({
        ...state,
        isLoadingDetails: true,
    }))
    .handleAction(actions.getFaxMailboxesDetails.success, (state, action) => ({
        ...state,
        isLoadingDetails: false,
        faxMailboxesList: {
            items: [action.payload.item],
            total: 1,
            isLoading: false,
            allTotal: 1,
        },
    }))
    .handleAction(actions.getFaxMailboxesDetails.failure, (state, action) => ({
        ...state,
        isLoadingDetails: false,
        apiError: action.payload
    }))
    .handleAction(actions.setFaxMailboxesStatus.success, (state, action) => {
        const updated =
            updateStatusForAccount(
                state,
                action.payload.account_info.i_account,
                action.payload.account_info.blocked,
            ) || [];

        return {
            ...state,
            faxMailboxesList: {
                items: updated,
                total: state.faxMailboxesList?.total || updated.length,
                isLoading: false,
                allTotal: state.faxMailboxesList?.allTotal || 0,
            },
        };
    })
    .handleAction(actions.editFaxMailbox.request, (state) => ({
        ...state,
        isFormSending: true,
    }))
    .handleAction(actions.editFaxMailbox.success, (state) => ({
        ...state,
        isFormSending: false,
    }))
    .handleAction(actions.editFaxMailbox.failure, (state, action) => ({
        ...state,
        createNewFaxMailboxesErrors: action.payload,
        isFormSending: false,
    }))
    .handleAction(actions.deleteCustomerFaxMailbox.request, (state) => ({
        ...state,
        deletingInProgress: true,
    }))
    .handleAction(actions.deleteCustomerFaxMailbox.success, (state) => ({
        ...state,
        deletingInProgress: false,
    }))
    .handleAction(actions.deleteCustomerFaxMailbox.failure, (state) => ({
        ...state,
        deletingInProgress: false,
    }));

export default faxMailboxesReducer;

const updateStatusForAccount = (
    state: FaxMailboxesStateType,
    i_account: string | number,
    status: string,
) => {
    return state.faxMailboxesList?.items.map((o) => {
        if (!o.account_info) {
            return o;
        }

        if (o.i_account == i_account) {
            return {
                ...o,
                account_info: {
                    ...o.account_info,
                    blocked: status == YesNo.Yes ? YesNo.Yes : YesNo.No,
                },
            };
        }
        return o;
    });
};
