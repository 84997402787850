import React, { useEffect, useState } from 'react';
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@material-ui/core';
import { Columns, RowData, RowsProp } from '@material-ui/data-grid';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from 'react-beautiful-dnd';
import { DataTableProps } from './DataTable';
import { Reorder } from '@material-ui/icons';
import { Colors } from '../../styles/Colors';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import CustomizedTooltip from '../Tooltip/Tooltip';
import Loader from '../Loader/Loader';

export const reorder = (
    list: RowsProp,
    startIndex: number,
    endIndex: number,
) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const useStyles = makeStyles(() => ({
    table: {
        position: 'relative',
        '& .MuiTableCell-root': {
            borderBottom: 0,
        },

        '& .MuiTableRow-root': {
            alignItems: 'center',
        },

        '& .MuiTableBody-root': {},
    },
    pagination: {
        '& .MuiTablePagination-select': {
            backgroundColor: Colors.White,
            borderBottom: `1px solid ${Colors.Gray}`,
            textAlign: 'left',
            textAlignLast: 'unset',
        },
        '& .MuiTablePagination-selectRoot': {
            '& .MuiInputBase-input': {
                padding: '12px !important',
            },
        },
        '& .MuiInputBase-root': {
            width: 82,
        },
    },
    headerRow: {
        display: 'flex',
    },
    header: {
        backgroundColor: Colors.LightGray,
        '& .MuiTableCell-root': {
            padding: '20px 0 !important',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 12,
        },
    },
    noData: {
        color: Colors.LightGraySecondary2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
}));

const BasicTableRow = ({
    item,
    index,
    columns,
}: {
    item: RowData;
    index: number;
    columns: Columns;
}) => {
    const headerColumn = columns.find((v) => v.field === 'header');
    const footerColumn = columns.find((v) => v.field === 'footer');

    return (
        <TableRow>
            {headerColumn?.renderCell && (
                <TableCell>
                    {headerColumn?.renderCell?.({
                        getValue: (name) => item[name],
                        field: '',
                        value: '',
                        colDef: {},
                        row: { id: 0, ...item },
                        api: {},
                        rowIndex: index,
                    })}
                </TableCell>
            )}
            <TableCell
                style={{
                    display: 'flex',
                    flex: 1,
                }}
            >
                {columns
                    .filter((v) => v.field !== 'header' && v.field !== 'footer')
                    .map((v, id) => (
                        <div
                            key={'row' + id}
                            style={{
                                flex: v.flex ?? 'unset',
                                width: v.width,
                                display: 'flex',
                            }}
                        >
                            {v.renderCell?.({
                                getValue: (name) => item[name],
                                field: '',
                                value: '',
                                colDef: {},
                                row: { id: 0, ...item },
                                api: {},
                                rowIndex: index,
                            }) ?? <span>{item[v?.headerName || '']}</span>}
                        </div>
                    ))}
            </TableCell>
            {footerColumn?.renderCell && (
                <TableCell>
                    {footerColumn?.renderCell?.({
                        getValue: (name) => item[name],
                        field: '',
                        value: '',
                        colDef: {},
                        row: { id: 0, ...item },
                        api: {},
                        rowIndex: index,
                    })}
                </TableCell>
            )}
        </TableRow>
    );
};

const DragItem = ({
    item,
    index,
    columns,
    dragEnable = true,
    dragIconWidth,
}: {
    item: RowData;
    index: number;
    columns: Columns;
    dragEnable?: boolean;
    dragIconWidth: number;
}) => {
    const headerColumn = columns.find((v) => v.field === 'header');

    const { t } = useTranslation();

    return (
        <TableRow>
            <Draggable
                draggableId={'draggable-item' + index + item.id}
                index={index}
            >
                {(provided) => (
                    <>
                        {headerColumn?.renderCell && (
                            <TableCell>
                                {headerColumn?.renderCell?.({
                                    getValue: (name) => item[name],
                                    field: '',
                                    value: '',
                                    colDef: {},
                                    row: { id: 0, ...item },
                                    api: {},
                                    rowIndex: index,
                                })}
                            </TableCell>
                        )}

                        <TableCell
                            key={item.id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{
                                ...provided.draggableProps.style,
                                display: 'flex',
                                flex: 1,
                            }}
                        >
                            {dragEnable && (
                                <div
                                    data-qa="drag-cell"
                                    style={{
                                        width: dragIconWidth,
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: 'inherit',
                                    }}
                                    {...provided.dragHandleProps}
                                >
                                    <CustomizedTooltip
                                        interactive
                                        title={t(
                                            'tooltips:extensions.changeOrder',
                                        )}
                                    >
                                        <Reorder
                                            htmlColor={Colors.Gray5}
                                            style={{ marginLeft: 10 }}
                                        />
                                    </CustomizedTooltip>
                                </div>
                            )}

                            {columns
                                .filter((v) => v.field !== 'header')
                                .map((v, id) => {
                                    return (
                                        <div
                                            key={'column' + id}
                                            style={{
                                                flex: v.flex ?? 'unset',
                                                width: v.width,
                                                display: 'flex',
                                            }}
                                        >
                                            {v.renderCell?.({
                                                getValue: (name) => item[name],
                                                field: '',
                                                value: '',
                                                colDef: {},
                                                row: { id: 0, ...item },
                                                api: {},
                                                rowIndex: index,
                                            }) ?? (
                                                <span>
                                                    {item[v?.headerName || '']}
                                                </span>
                                            )}
                                        </div>
                                    );
                                })}
                        </TableCell>
                    </>
                )}
            </Draggable>
        </TableRow>
    );
};

export type DragDropDataTableProps = DataTableProps & {
    headerVisible?: boolean;
    onChangeItemPosition?: (startIndex: number, endIndex: number) => void;
    classes?: { pagination?: string, rootTable?:string };
    dragEnable?: boolean;
    className?: string;
    dragIconWidth?: number;
    placeholderComponentWhenEmptyData?: boolean;
    dataQa?: string;
    loading?: boolean;
    rowsPerPageOptions?: number[];
    initialPageSize?: number;
    hidePagination?: boolean;
};

const DragDropDataTable: React.VFC<DragDropDataTableProps> = ({
    rows,
    columns,
    rowCount,
    currentPage,
    onPageChange,
    onPageSizeChange,
    onChangeItemPosition,
    headerVisible = false,
    classes: customClasses = {},
    dragEnable = true,
    className,
    dataQa,
    dragIconWidth = 30,
    placeholderComponentWhenEmptyData,
    loading,
    rowsPerPageOptions,
    initialPageSize,
    paginationMode,
    hidePagination,
}) => {
    const [items, setItems] = useState<RowsProp>(rows || []);
    const [pageSize, setPageSize] = useState(initialPageSize ?? 10);
    const [page, setPage] = useState(0);

    const { t } = useTranslation();

    const classes = useStyles();

    useEffect(() => {
        setItems(rows || []);
    }, [rows]);

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const newItems = reorder(
            items,
            result.source.index,
            result.destination.index,
        );

        onChangeItemPosition?.(result.source.index, result.destination.index);

        setItems(newItems);
    };

    return (
        <TableContainer data-qa={dataQa} className={classNames(customClasses.rootTable)}>
            <Table className={classNames(classes.table, className)}>
                {headerVisible && (
                    <TableHead className={classes.header}>
                        <TableRow>
                            <TableCell>
                                {dragEnable && (
                                    <div style={{ width: dragIconWidth }}></div>
                                )}

                                {columns.map((v, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            flex: v.flex ?? 'unset',
                                            width: v.width,
                                        }}
                                    >
                                        {v.renderHeader?.({
                                            field: '',
                                            colDef: {},
                                            api: {},
                                            colIndex: index,
                                        }) || <span>{v.headerName}</span>}
                                    </div>
                                ))}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                )}

                {dragEnable ? (
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="list">
                            {(provided) => (
                                <TableBody
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {items
                                        .slice(
                                            page * pageSize,
                                            page * pageSize + pageSize,
                                        )
                                        .map((item: RowData, index: number) => (
                                            <DragItem
                                                item={{
                                                    ...item,
                                                    id: 'drag' + item?.id,
                                                }}
                                                index={index + page * pageSize}
                                                key={
                                                    'basicrow' +
                                                    (index + page * pageSize)
                                                }
                                                columns={columns}
                                                dragIconWidth={dragIconWidth}
                                            />
                                        ))}
                                    {provided.placeholder}
                                </TableBody>
                            )}
                        </Droppable>
                    </DragDropContext>
                ) : (
                    <TableBody>
                        {(paginationMode === 'server'
                            ? items
                            : items.slice(
                                  page * pageSize,
                                  page * pageSize + pageSize,
                              )
                        ).map((item: RowData, index: number) => (
                            <BasicTableRow
                                item={item}
                                index={index + page * pageSize}
                                key={'basicrow' + (index + page * pageSize)}
                                columns={columns}
                            />
                        ))}
                        {loading && <Loader dataQa="loader" absolutePosition />}
                    </TableBody>
                )}
            </Table>

            {!!rows?.length && !hidePagination && (
                <TablePagination
                    className={classNames(
                        classes.pagination,
                        customClasses.pagination,
                    )}
                    data-qa={'drag-drop-pagination'}
                    component="div"
                    count={rowCount}
                    page={currentPage ?? page}
                    rowsPerPageOptions={rowsPerPageOptions || [5, 10, 15]}
                    onChangePage={(_, page) => {
                        setPage(page);
                        onPageChange?.({
                            page,
                            pageCount: rowCount,
                            pageSize,
                            rowCount,
                            paginationMode: 'server',
                        });
                    }}
                    rowsPerPage={pageSize}
                    onChangeRowsPerPage={(event) => {
                        const value = parseInt(event.target.value, 10);
                        setPageSize(value);
                        onPageSizeChange?.({
                            page: 0,
                            pageCount: rowCount,
                            pageSize: value,
                            rowCount,
                            paginationMode: 'server',
                        });
                    }}
                    labelRowsPerPage={
                        <span>{t('screens:ringGroups.itemsPerPage')}:</span>
                    }
                />
            )}

            {placeholderComponentWhenEmptyData && !rows?.length && !loading && (
                <div className={classes.noData}>{t('common:noData')}</div>
            )}
        </TableContainer>
    );
};

export default DragDropDataTable;
