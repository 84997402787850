import {TFunction} from 'react-i18next';
import {CellProps, Column} from 'react-table';
import {AutoAttendantListItem} from '../../../store/types/AutoAttendant';
import history from '../../../history';
import ExtensionDIDNumber from '../../../components/Extensions/ExtensionDIDNumber';
import EmptyRowContent from '../../../components/DataTable/EmptyRowContent';
import React from 'react';
import {ExtensionsListItem} from '../../../store/reducers/extensions/extensions/reducer';
import {Edit} from '@material-ui/icons';
import i18n from '../../../services/i18n';
import {makeStyles} from '@material-ui/core/styles';
import {Colors} from '../../../styles/Colors';
import {selectId} from '../../../utils/autoAttendants/selectId';
import {getRoute, Routes} from '../../../routes/routes';
import {ReactComponent as CallHistory} from '../../../assets/call_history.svg';

import {Delete} from '@material-ui/icons';
import CustomizedIconButton from '../../../components/IconButton/IconButton';
import OverflowTooltip from '../../../components/OverflowTooltip/OverflowTooltip';
import PermissionProvider from '../../../components/PermissionProvider/PermissionProvider';
import {Permission} from '../../../store/types/Permission';
import PermissionLinkCell from "../../../components/DataTable/PermissionLinkCell";

const additionalMargin = '7%';

export const useStyles = makeStyles(() => ({
    mainContainer: {
        height: '100vh',
        position: 'relative',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        background: Colors.SmokeBackground,
        '& .MuiButton-label': {
            color: Colors.White,
        },

        '& .MuiDataGrid-cell': {
            alignItems: 'center',
            height: 64,
            color: Colors.Gray5,
        },
        '& thead tr.MuiTableRow-root th.MuiTableCell-root.MuiTableCell-head:first-child': {
            textAlign: 'unset !important',
            paddingLeft: 0,
            marginLeft: additionalMargin,
        },
        '& thead tr.MuiTableRow-root th.MuiTableCell-root.MuiTableCell-head:last-child': {
            paddingLeft: '2%',
        },

        '& tr.MuiTableRow-root td.MuiTableCell-root.MuiTableCell-body:first-child': {
            paddingLeft: additionalMargin,
        },
    },

    scrollable: {
        overflowY: 'auto',
        flex: 1,
        padding: '48px 96px',
        '& .MuiTableContainer-root': {
            width: 'unset !important'
        }
    },
    header: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 25,
        '& h2': {
            fontSize: 32,
            lineHeight: '24px',
            marginBlockEnd: 8,
            marginBlockStart: 4,
            fontWeight: 500,
        },
        '& p': {
            fontSize: 11,
            lineHeight: '16px',
            opacity: 0.38,
            marginBlockStart: 0,
        },
    },
    filters: {
        display: 'flex',
        alignItems: 'center',
    },
    filtersHidden: {
        width: 50,
        overflow: 'hidden',
        marginBottom: -92,
    },
    pressableSearchIcon: {
        marginTop: 10,
        marginRight: 17,
        marginLeft: 17,
        cursor: 'pointer',
    },
    filtersContainer: {
        marginBottom: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    filtersInputs: {
        flex: 1,
        display: 'flex',
        '& MuiFormControl-root': {
            flex: 1,
            maxWidth: 400,
        },
    },
    headerRow: {
        textTransform: 'uppercase',
        border: 0,
    },
    headerCell: {
        fontWeight: 700,
        fontSize: 12,
    },
    row: {
        borderBottom: 0,
    },
    filterSeparation: {
        marginRight: 18,
        width: '390px',
    },
    primaryModalButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    tableContainer: {
        maxWidth: 1040,
        overflowX: 'hidden',

        '& .MuiTableRow-head': {
            '& :nth-child(1)': {
                textAlign: 'center',
            },
        },
    },
    centeredRow: {
        display: 'flex',
        justifyContent: 'center',
    },
    searchButton: {
        marginLeft: 6,
        width: 96,
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'center',
        height: 56,
        marginTop: 24,
    },
    itemsContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    actions: {
        display: 'flex',
        paddingLeft: 0,
        marginLeft: '-3.5rem',

        '& .MuiIconButton-root': {
            marginRight: 5,
        },
    },
    actionWrapper: {
        marginRight: 5,
        marginLeft: 5,
    },
    callQueue: {
        width: '80px',
        paddingLeft: 25,
    },
    maxHeightCell: {
        flex: 1,
        height: 50,
        alignSelf: 'flex-start',
        alignItems: 'center',
        display: 'flex',
        overflow: 'hidden',
    },
    disableOverflow: {
        overflow: 'unset',
    },
    iconMargin: {
        marginLeft: 22,
        '&:hover': {
            cursor: 'pointer',
        },
    },

    table: {
        marginTop: 24,
        '& .MuiTableBody-root': {
            '& tr': {
                maxHeight: 71,
            },
        },
        '& .MuiTableHead-root': {
            height: 64,
            '& .MuiTableRow-root': {
                height: 64,
            },
        },
        '& .MuiTableFooter-root': {
            '& .MuiTableRow-footer': {
                '& .MuiTableCell-footer': {
                    '& .MuiTablePagination-root': {
                        height: '64px !important',
                    },
                },
            },
        },
    },
    deleteDialogButtons: {
        '& button': {
            minWidth: 90,
        },

        '& .MuiDialogContent-root': {
            paddingTop: 26,
            paddingBottom: 26,
            minWidth: 'unset !important',
            background: Colors.White,

            '& p': {
                marginBottom: 0,
            },
        },
        '& .MuiDialogActions-root': {
            paddingTop: 8,
            paddingBottom: 8,
        },
    },
    nameLink: {
        color: Colors.Secondary1,
        cursor: 'pointer',
    },
    didNumber: {
        '& .did-number-wrapper': {
            '& span': {
                fontWeight: 400,
                color: Colors.Text,
            },
        },
    },
    emailText: {
        color: Colors.Gray5,
        paddingRight: 40,
    },
}));

export const generateColumns = (
    t: TFunction<string>,
    fetchDDINumbers: (i_account: number) => void,
    classes: ReturnType<typeof useStyles>,
    deleteExtension: (id: string, i_customer: number, name: string) => void,
    showHistory: (i_account: number, extensionName?: string) => void,
): Column<AutoAttendantListItem>[] => {
    return [
        {
            accessor: 'name',
            Header: t<string>('common:name'),
            width: 1.3,
            Cell: function Cell(params) {
                const id = selectId(params.row.original);
                const name =
                    params.row.original.name ??
                    t('screens:autoAttendants.unnamed');

                const permission = Permission.CloudPBX.FaxMailboxes.FaxMailboxDetails.value;
                return (
                    <PermissionLinkCell
                        text={name}
                        onClick={() =>
                            history.push(
                                getRoute(Routes.FaxMailboxesDetails, {
                                    id: id,
                                }),
                            )
                        }
                        permissions={permission}
                    />
                );
            },
        },
        {
            accessor: 'numbers',
            width: 1.5,
            Header: t<string>('screens:didNumbers.didNumber'),
            Cell: function Cell(params) {
                const numbers = (
                    params.row.original.account_info?.alias_did_number_list ||
                    []
                ).map((v) => v.did_number);

                if (numbers.length > 0) {
                    return (
                        <div className={classes.didNumber}>
                            <ExtensionDIDNumber
                                showFooter={false}
                                extNumbers={numbers}
                            />
                        </div>
                    );
                }
                return <EmptyRowContent/>;
            },
        },
        {
            Header: t<string>('screens:faxMailboxes.email'),
            accessor: (row) => row.account_info!.email,
            width: 2.5,
            id: '3',
            Cell: function Cell(
                params: React.PropsWithChildren<CellProps<ExtensionsListItem, string[] | undefined>>,
            ) {
                const email = params.row.original.account_info!.email;
                return (
                    <OverflowTooltip
                        tooltip={email}
                        text={email}
                        classes={{text: classes.emailText}}
                        copy={false}
                    />
                );
            },
        },
        {
            Header: t<string>('common:actions'),
            accessor: 'i_account',
            maxWidth: 170,
            minWidth: 170,
            Cell: function Cell(params) {
                const id = selectId(params.row.original);
                return (
                    <div className={classes.actions}>
                        <PermissionProvider
                            permission={
                                Permission.CloudPBX.FaxMailboxes
                                    .ViewFaxTransmissionHistory.value
                            }
                            skipReadOnly
                        >
                            <CustomizedIconButton
                                tooltipText={t(
                                    'tooltips:faxMailboxes.viewHistory',
                                )}
                                dataQa="show-call-history-button"
                                dataTestId="show-call-history-button"
                                onClick={() =>
                                    showHistory(
                                        params.row.original.i_account,
                                        params.row.original.name ??
                                        t('screens:autoAttendants.unnamed'),
                                    )
                                }
                            >
                                <CallHistory/>
                            </CustomizedIconButton>
                        </PermissionProvider>

                        <PermissionProvider
                            permission={
                                Permission.CloudPBX.FaxMailboxes
                                    .FaxMailboxDetails.value
                            }
                            skipReadOnly
                        >
                            <CustomizedIconButton
                                tooltipText={t('common:edit')}
                                dataQa="edit-icon"
                                dataTestId="edit-icon"
                                onClick={() =>
                                    history.push(
                                        getRoute(Routes.FaxMailboxesDetails, {
                                            id: id,
                                        }),
                                    )
                                }
                                className={classes.iconMargin}
                            >
                                <Edit htmlColor={Colors.ListIconColor}/>
                            </CustomizedIconButton>
                        </PermissionProvider>

                        {params.row.original.delete_enable && (
                            <PermissionProvider
                                permission={
                                    Permission.CloudPBX.FaxMailboxes
                                        .DeleteFaxMailbox.value
                                }
                            >
                                <CustomizedIconButton
                                    tooltipText={t('common:delete')}
                                    dataQa="remove-list-item-button"
                                    dataTestId="remove-list-item-button"
                                    onClick={() => {
                                        deleteExtension(
                                            params.row.original?.extension_id ||
                                            '',
                                            params.row.original.i_customer!,
                                            params.row.original.name || '',
                                        );
                                    }}
                                    className={classes.iconMargin}
                                >
                                    <Delete/>
                                </CustomizedIconButton>
                            </PermissionProvider>
                        )}
                    </div>
                );
            },
        },
    ];
};

export const filterData = (
    filters: {
        name?: string;
        email?: string;
        did?: string;
        status?: string;
    },
    source: AutoAttendantListItem[],
): AutoAttendantListItem[] => {
    let items = [...source];

    if (filters.name?.length) {
        const unnamedText = i18n.t<string>('screens:autoAttendants.unnamed');

        items = items.filter(
            (o) =>
                o.name?.includes(filters.name || '') ||
                (o.name == undefined &&
                    unnamedText.includes(filters.name || '')),
        );
    }

    if (filters.email?.length) {
        items = items.filter((o) =>
            o.account_info?.email?.includes(filters.email || ''),
        );
    }

    if (filters.did?.length) {
        items = items.filter(
            (o) =>
                !!o.numbers?.filter((o) => o.includes(filters.did || ''))
                    .length,
        );
    }

    if (filters.status?.length) {
        items = items.filter((o) =>
            o.account_info?.blocked?.includes(filters.status || ''),
        );
    }

    return items;
};
