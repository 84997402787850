import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import toast from 'react-hot-toast';
import { put, takeLatest, select, call, delay } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { actions } from '../..';
import { MenuActionFormType } from '../../../components/Forms/AutoAttendantsMenus/ActionsForm.utils';
import i18n from '../../../services/i18n';
import { compareObjectsAndReturnDifferencesInValues } from '../../../utils/compareObjects';
import { timeWindowFromTimeWindowIntervals } from '../../../utils/extensions/CallScreeningTimeWindowToPeriod';
import JSONFormData from '../../../utils/JSONFormData';
import { showErrorToast } from '../../../utils/showErrorToast';
import { EditAutoAttendantsMenuForm } from '../../../views/AutoAttendants/AutoAttendantsMenuDetails';
import { ExtensionsListItem } from '../../reducers/extensions/extensions/reducer';
import { ReduxState } from '../../types';
import {
    AutoAttendantMenu,
    AutoAttendantMenuTransition,
    MenuAction,
    UserInput,
} from '../../types/AutoAttendant';
import {
    getAutoAttendantInfo,
    getAutoAttendantMenus,
    getDISAState,
} from '../autoAttendants/saga';
import { AutoAttendant } from '../../../services/endpoints';
import Config from '../../../config.json';
import { AddNewMenuForm } from '../../../components/AutoAttendants/CreateNewMenu/utils';
import { getCallQueues } from '../callQueues/saga';
import {IntervalStatus} from "../../../components/IntervalSelect/IntervalSelect.utils";

export function* getMenuDetails(
    action: ActionType<typeof actions.getMenuDetails.request>,
) {
    try {
        yield call(
            getAutoAttendantInfo,
            actions.getAutoAttendantDetails.request({
                id: action.payload.autoAttendantId,
            }),
        );

        const autoAttendantInfo: ExtensionsListItem | undefined = yield select(
            (state: ReduxState) => state.autoAttendants.autoAttendantInfo,
        );
        
        const { callQueues } = yield select(
            (state: ReduxState) => state.callQueues,
        );

        if (
            autoAttendantInfo?.account_info?.id &&
            autoAttendantInfo?.account_info?.um_domain
        ) {
            yield call(
                getAutoAttendantMenus,
                actions.getAutoAttendantMenus.request({
                    login: autoAttendantInfo?.account_info?.id,
                    domain: autoAttendantInfo?.account_info?.um_domain,
                    ignoreCache: true
                }),
            );
    
            if(!callQueues) {
                yield call(getCallQueues);
            }
            
            yield call(getDISAState);

            const autoAttendantMenus:
                | AutoAttendantMenu[]
                | undefined = yield select(
                (state: ReduxState) => state.autoAttendants.autoAttendantMenus,
            );

            if (autoAttendantMenus) {
                const menu = autoAttendantMenus.find(
                    (v) => v.i_menu === parseInt(action.payload.menuId),
                );
                if (menu) {
                    yield call(
                        getMenuTransitions,
                        actions.getMenuTransitions.request({
                            menuId: menu.i_menu,
                            login: autoAttendantInfo?.account_info?.id,
                            domain: autoAttendantInfo?.account_info?.um_domain,
                        }),
                    );

                    yield put(actions.getMenuDetails.success(menu));
                }
            }
        }
    } catch (err) {
        yield put(actions.getMenuDetails.failure());
    }
}

export function* getMenuTransitions(
    action: ActionType<typeof actions.getMenuTransitions.request>,
) {
    const { session_id, access_token, csrf_token } = yield select(
        (state: ReduxState) => state.auth,
    );

    const body = new JSONFormData(session_id, csrf_token);

    body.delete('auth_info');
    body.append(
        'auth_info',
        JSON.stringify({
            login: action.payload.login,
            domain: action.payload.domain,
        }),
    );
    body.setParams({
        i_menu: action.payload.menuId,
    });

    const response: AxiosResponse<{
        transition_list: AutoAttendantMenuTransition[];
    }> = yield axios.post(AutoAttendant.GetMenuTransitionList, body, {
        baseURL: Config.SIP_API_URL,
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    });

    yield put(
        actions.getMenuTransitions.success(response.data.transition_list),
    );
}

export function* getMenuPromptFile(
    action: ActionType<typeof actions.getMenuPromptFile.request>,
) {
    try {
        const { session_id, access_token, csrf_token } = yield select(
            (state: ReduxState) => state.auth,
        );

        const autoAttendantInfo: ExtensionsListItem | undefined = yield select(
            (state: ReduxState) => state.autoAttendants.autoAttendantInfo,
        );

        const body = new JSONFormData(session_id, csrf_token);

        body.delete('auth_info');
        body.append(
            'auth_info',
            JSON.stringify({
                login: autoAttendantInfo?.account_info?.id,
                domain: autoAttendantInfo?.account_info?.um_domain,
            }),
        );
        body.setParams({
            i_menu_transition: action.payload.i_menu_transition,
        });

        const response: AxiosResponse<Blob> = yield axios.post(
            AutoAttendant.GetMenuTransitionPrompt,
            body,
            {
                baseURL: Config.SIP_API_URL,
                headers: {
                    Authorization: `Bearer ${access_token}`,
                    // 'Content-Type': 'audio/basic',
                },
                responseType: 'blob',
            },
        );

        const blobFile: any = response.data;
        //ts-ignore
        blobFile.lastModifiedDate = new Date();
        //ts-ignore
        blobFile.name = 'prompt.au';

        action.payload.callback?.(<File>blobFile);
        yield put(actions.getMenuPromptFile.success(<File>blobFile));
    } catch (err) {
        yield put(actions.getMenuPromptFile.failure());
    }
}

export function* editMenu(action: ActionType<typeof actions.editMenu.request>) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams(action.payload);

        const dataToSave = compareObjectsAndReturnDifferencesInValues(
            action.payload.initialValues,
            action.payload.changedValues,
        );

        yield editMenuSettings(dataToSave, action);
        yield editMenuPrompts(dataToSave, action);
        yield editMenuActions(dataToSave, action);

        toast(i18n.t<string>('screens:autoAttendants.menuEdited'));
        yield put(actions.editMenu.success());

        yield delay(1000);

        location?.replace(
            `${action.payload.menuId}?${qs.stringify({
                tab: action.payload.redirectTab,
            })}`,
        );
    } catch (err: any) {
        showErrorToast(err.response?.data?.faultstring);
        yield put(actions.editMenu.failure(err.response?.data));
    }
}

export function* editMenuSettings(
    changedValues: Partial<EditAutoAttendantsMenuForm>,
    action: ActionType<typeof actions.editMenu.request>,
) {
    const { session_id, access_token, csrf_token } = yield select(
        (state: ReduxState) => state.auth,
    );

    const autoAttendantInfo: ExtensionsListItem | undefined = yield select(
        (state: ReduxState) => state.autoAttendants.autoAttendantInfo,
    );

    const body = new JSONFormData(session_id, csrf_token);

    body.delete('auth_info');
    body.append(
        'auth_info',
        JSON.stringify({
            login: autoAttendantInfo?.account_info?.id,
            domain: autoAttendantInfo?.account_info?.um_domain,
        }),
    );

    const { menuId, initialValues } = action.payload;

    const params: { [key in string]: unknown } = {
        i_menu: menuId,
    };

    if (changedValues.intervals) {
        const activity =
            changedValues.intervals.activity !== undefined
                ? changedValues.intervals.activity
                : initialValues.intervals.activity;

        if (activity === IntervalStatus.Always) {
            params.period = 'Always';
            params.period_desc = 'Always';
        } else {
            const timeWindow = timeWindowFromTimeWindowIntervals({
                intervals: changedValues.intervals.intervals || [],
                timeFilterIndex: 0,
                activity: activity,
            });
            params.period = timeWindow.period;
            params.period_desc = timeWindow.description;
        }
    }

    if (changedValues.name !== undefined) {
        params.name = changedValues.name;
    }

    if (changedValues.allowCallersToDialKnownNumber !== undefined) {
        params.direct_dial_enabled = changedValues.allowCallersToDialKnownNumber
            ? 'Y'
            : 'N';
    }

    if (changedValues.callersToDialKnownNumberTimeout != undefined) {
        params.next_digit_timeout =
            changedValues.callersToDialKnownNumberTimeout;
    }

    if (changedValues.errorsCount != undefined) {
        params.replay_menu_times = changedValues.errorsCount;
    }

    if (changedValues.introPromptStatus !== undefined) {
        params.msg_intro_type = changedValues.introPromptStatus
            ? 'custom'
            : 'standard';
    }

    if (changedValues.menuPromptStatus !== undefined) {
        params.msg_menu_type = changedValues.menuPromptStatus
            ? 'custom'
            : 'standard';
    }

    if (changedValues.onTimeoutPromptStatus !== undefined) {
        params.msg_timeout_type = changedValues.onTimeoutPromptStatus
            ? 'custom'
            : 'standard';
    }

    if (changedValues.onUnavailableSelectionPromptStatus !== undefined) {
        params.msg_disabled_type = changedValues.onUnavailableSelectionPromptStatus
            ? 'custom'
            : 'standard';
    }

    if (Object.keys(params).length > 1) {
        body.setParams({ menu_info: params });

        yield axios.post(AutoAttendant.UpdateMenu, body, {
            baseURL: Config.SIP_API_URL,
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
    }

    if (
        changedValues.inactiveMenuAction !== undefined ||
        changedValues.queue ||
        changedValues.menu ||
        changedValues.transferDestination !== undefined ||
        changedValues.playBeforeAction !== undefined
    ) {
        yield editMenuTransition(menuId, {
            userInput: UserInput.NotActive,
            action:
                changedValues.inactiveMenuAction ||
                initialValues.inactiveMenuAction,
            playBeforeActionStatus:
                changedValues.playBeforeAction !== undefined
                    ? changedValues.playBeforeAction
                    : initialValues.playBeforeAction,
            queue: changedValues.queue || initialValues.queue,
            menu: changedValues.menu || initialValues.menu,
            transferCallerToPhoneNumberExtension:
                changedValues.transferDestination ||
                initialValues.transferDestination,
            maxDigits: changedValues.maxDigits || initialValues.maxDigits,
            playBeforeActionFile: changedValues.recordBeforeActionFile,
        });
    }
}

export function* editMenuTransition(
    menuId: number,
    menuTransition: MenuActionFormType,
    customLogin?: string,
    customDomain?: string,
) {
    const { session_id, access_token, csrf_token } = yield select(
        (state: ReduxState) => state.auth,
    );

    const autoAttendantInfo: ExtensionsListItem | undefined = yield select(
        (state: ReduxState) => state.autoAttendants.autoAttendantInfo,
    );

    const body = new JSONFormData(session_id, csrf_token);

    body.delete('auth_info');
    body.append(
        'auth_info',
        JSON.stringify({
            login: customLogin || autoAttendantInfo?.account_info?.id,
            domain: customDomain || autoAttendantInfo?.account_info?.um_domain,
        }),
    );

    const params: { [key in string]: unknown } = {
        event: menuTransition.userInput,
        action: menuTransition.action,
        play_prompt: menuTransition.playBeforeActionStatus ? 'Y' : 'N',
    };

    if (menuTransition.action === MenuAction.Queue) {
        params.target_i_queue = menuTransition.queue?.i_c_queue;
    }

    if (menuTransition.action === MenuAction.Menu) {
        params.target_i_menu = menuTransition.menu?.i_menu;
    }

    if (
        menuTransition.action === MenuAction.Transfer ||
        menuTransition.action === MenuAction.TransferToE164Number
    ) {
        params.destination =
            menuTransition.transferCallerToPhoneNumberExtension;
    }

    if (menuTransition.action === MenuAction.PromptForExtension ||
        menuTransition.action === MenuAction.DialExtensionDirectly) {
        params.max_size = menuTransition.maxDigits;
    }

    body.setParams({
        i_menu: menuId,
        transition_info: params,
    });

    const response: AxiosResponse<{
        i_menu_transition: number;
    }> = yield axios.post(AutoAttendant.SetMenuTransition, body, {
        baseURL: Config.SIP_API_URL,
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    });

    if (menuTransition.playBeforeActionFile) {
        yield uploadMenuTransitionPrompt(
            actions.uploadMenuTransitionPrompt.request({
                i_menu_transition: response.data.i_menu_transition,
                file: menuTransition.playBeforeActionFile,
            }),
        );
    }

    if (
        menuTransition.userInput === UserInput.NoInput &&
        menuTransition.timeout !== undefined
    ) {
        body.setParams({
            menu_info: {
                i_menu: menuId,
                first_digit_timeout: parseInt(menuTransition.timeout),
            },
        });

        yield axios.post(AutoAttendant.UpdateMenu, body, {
            baseURL: Config.SIP_API_URL,
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
    }
}

export function* editMenuPrompts(
    changedValues: Partial<EditAutoAttendantsMenuForm>,
    action: ActionType<typeof actions.editMenu.request>,
) {
    if (changedValues.introPromptFile) {
        yield call(
            uploadMenuPrompt,
            actions.uploadMenuPrompt.request({
                i_menu: action.payload.menuId,
                prompt_type: 'intro',
                file: changedValues.introPromptFile,
            }),
        );
    }

    if (changedValues.menuPromptFile) {
        yield call(
            uploadMenuPrompt,
            actions.uploadMenuPrompt.request({
                i_menu: action.payload.menuId,
                prompt_type: 'menu',
                file: changedValues.menuPromptFile,
            }),
        );
    }

    if (changedValues.onTimeoutPromptFile) {
        yield call(
            uploadMenuPrompt,
            actions.uploadMenuPrompt.request({
                i_menu: action.payload.menuId,
                prompt_type: 'timeout',
                file: changedValues.onTimeoutPromptFile,
            }),
        );
    }

    if (changedValues.onUnavailableSelectionPromptFile) {
        yield call(
            uploadMenuPrompt,
            actions.uploadMenuPrompt.request({
                i_menu: action.payload.menuId,
                prompt_type: 'disabled',
                file: changedValues.onUnavailableSelectionPromptFile,
            }),
        );
    }
}

export function* editMenuActions(
    changedValues: Partial<EditAutoAttendantsMenuForm>,
    action: ActionType<typeof actions.editMenu.request>,
) {
    if (changedValues.actions !== undefined) {
        for (const changedAction of changedValues.actions) {
            const oldValue = action.payload.initialValues.actions.find((v) => {
                return changedAction.userInput === v.userInput;
            });

            if (
                !!oldValue &&
                !Object.keys(
                    compareObjectsAndReturnDifferencesInValues(
                        oldValue,
                        changedAction,
                    ),
                ).length
            ) {
                continue;
            }

            yield editMenuTransition(action.payload.menuId, changedAction);
        }

        const newTransitionsIds = changedValues.actions.filter((v) => v.transitionId !== undefined)?.map(
            (v) => v.transitionId,
        );

        if(newTransitionsIds)
        {
            for (const oldAction of action.payload.initialValues.actions) {
                if (
                    oldAction.transitionId !== undefined && !newTransitionsIds.includes(oldAction.transitionId)
                ) {
                    yield removeMenuTransition(oldAction);
                }
            }
        }
    }
}

export function* removeMenuTransition(menuTransition: MenuActionFormType) {
    const { session_id, access_token, csrf_token } = yield select(
        (state: ReduxState) => state.auth,
    );

    const autoAttendantInfo: ExtensionsListItem | undefined = yield select(
        (state: ReduxState) => state.autoAttendants.autoAttendantInfo,
    );

    const body = new JSONFormData(session_id, csrf_token);

    body.delete('auth_info');
    body.append(
        'auth_info',
        JSON.stringify({
            login: autoAttendantInfo?.account_info?.id,
            domain: autoAttendantInfo?.account_info?.um_domain,
        }),
    );

    body.setParams({
        i_menu_transition: menuTransition.transitionId,
    });

    yield axios.post(AutoAttendant.DelMenuTransition, body, {
        baseURL: Config.SIP_API_URL,
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    });
}

export function* uploadMenuTransitionPrompt(
    action: ActionType<typeof actions.uploadMenuTransitionPrompt.request>,
) {
    const { session_id, access_token, csrf_token } = yield select(
        (state: ReduxState) => state.auth,
    );

    const autoAttendantInfo: ExtensionsListItem | undefined = yield select(
        (state: ReduxState) => state.autoAttendants.autoAttendantInfo,
    );

    const body = new JSONFormData(session_id, csrf_token);

    body.delete('auth_info');
    body.append(
        'auth_info',
        JSON.stringify({
            login: autoAttendantInfo?.account_info?.id,
            domain: autoAttendantInfo?.account_info?.um_domain,
        }),
    );

    body.setParams({
        i_menu_transition: action.payload.i_menu_transition,
        prompt: 'prompt.au',
    });

    body.append('upload_file', action.payload.file);

    yield axios.post(AutoAttendant.SetMenuTransitionPrompt, body, {
        baseURL: Config.SIP_API_URL,
        headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'audio/*',
        },
    });

    yield put(actions.uploadMenuTransitionPrompt.success());
}

export function* uploadMenuPrompt(
    action: ActionType<typeof actions.uploadMenuPrompt.request>,
) {
    const { session_id, access_token, csrf_token } = yield select(
        (state: ReduxState) => state.auth,
    );

    const autoAttendantInfo: ExtensionsListItem | undefined = yield select(
        (state: ReduxState) => state.autoAttendants.autoAttendantInfo,
    );

    const body = new JSONFormData(session_id, csrf_token);

    body.delete('auth_info');
    body.append(
        'auth_info',
        JSON.stringify({
            login: autoAttendantInfo?.account_info?.id,
            domain: autoAttendantInfo?.account_info?.um_domain,
        }),
    );

    body.setParams({
        i_menu: action.payload.i_menu,
        prompt_type: action.payload.prompt_type,
        prompt: action.payload.file.name,
    });

    body.append('upload_file', action.payload.file);

    yield axios.post(AutoAttendant.SetMenuPrompt, body, {
        baseURL: Config.SIP_API_URL,
        headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'audio/*',
        },
    });

    yield put(actions.uploadMenuPrompt.success());
}

export function* removeMenu(
    action: ActionType<typeof actions.removeMenu.request>,
) {
    try {
        const { session_id, access_token, csrf_token } = yield select(
            (state: ReduxState) => state.auth,
        );

        const autoAttendantInfo: ExtensionsListItem | undefined = yield select(
            (state: ReduxState) => state.autoAttendants.autoAttendantInfo,
        );

        const body = new JSONFormData(session_id, csrf_token);

        body.delete('auth_info');
        body.append(
            'auth_info',
            JSON.stringify({
                login: autoAttendantInfo?.account_info?.id,
                domain: autoAttendantInfo?.account_info?.um_domain,
            }),
        );

        body.setParams({
            i_menu: action.payload.i_menu,
        });

        yield axios.post(AutoAttendant.DelMenu, body, {
            baseURL: Config.SIP_API_URL,
            headers: {
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'audio/*',
            },
        });

        location.replace(`/auto-attendants/${action.payload.autoAttendantId}`);

        toast(i18n.t<string>('screens:autoAttendants.menuRemoved'));
        yield put(actions.removeMenu.success());
    } catch (err: any) {
        showErrorToast(err.response?.data?.faultstring);
        yield put(actions.removeMenu.failure());
    }
}

export function* createMenuCall(
    form: AddNewMenuForm,
    login: string,
    domain: string,
) {
    const { session_id, access_token, csrf_token } = yield select(
        (state: ReduxState) => state.auth,
    );

    const body = new JSONFormData(session_id, csrf_token);

    body.delete('auth_info');
    body.append(
        'auth_info',
        JSON.stringify({
            login,
            domain,
        }),
    );

    const params: { [key in string]: unknown } = {};

    const {
        intervals,
        allowCallersToDialKnownNumber,
        name,
        callersToDialKnownNumberTimeout,
        errorsCount,
    } = form;

    params.name = name;

    if (intervals.activity === IntervalStatus.Always) {
        params.period = 'Always';
        params.period_desc = 'Always';
    } else {
        const timeWindow = timeWindowFromTimeWindowIntervals({
            intervals: intervals.intervals || [],
            timeFilterIndex: 0,
            activity: intervals.activity,
        });
        params.period = timeWindow.period;
        params.period_desc = timeWindow.description;
    }

    if (allowCallersToDialKnownNumber !== undefined) {
        params.direct_dial_enabled = allowCallersToDialKnownNumber ? 'Y' : 'N';
    }

    if (
        allowCallersToDialKnownNumber !== undefined &&
        allowCallersToDialKnownNumber &&
        callersToDialKnownNumberTimeout
    ) {
        params.next_digit_timeout = callersToDialKnownNumberTimeout;
    }

    if (errorsCount != undefined) {
        params.replay_menu_times = errorsCount;
    }

    body.setParams({
        menu_info: {
            ...params,
        },
    });

    const response: AxiosResponse<{
        i_menu: number;
    }> = yield axios.post(AutoAttendant.CreateMenu, body, {
        baseURL: Config.SIP_API_URL,
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    });

    return response;
}

export function* createNewMenu(
    action: ActionType<typeof actions.createMenu.request>,
) {
    try {
        const menus: AutoAttendantMenu[] = yield select(
            (state: ReduxState) => state.autoAttendants?.autoAttendantMenus,
        );

        const {
            inactiveMenuAction,
            playBeforeAction,
            queue,
            menu,
            transferDestination,
            maxDigits,
            recordBeforeActionFile,
        } = action.payload.dataToStore;

        const response: AxiosResponse<{
            i_menu: number;
        }> = yield createMenuCall(
            action.payload.dataToStore,
            action.payload.login,
            action.payload.domain,
        );

        if (response.data.i_menu) {
            yield editMenuTransition(response.data.i_menu, {
                userInput: UserInput.NotActive,
                action: inactiveMenuAction,
                playBeforeActionStatus: playBeforeAction,
                queue: queue,
                menu: menu,
                transferCallerToPhoneNumberExtension: transferDestination,
                maxDigits: maxDigits,
                playBeforeActionFile: recordBeforeActionFile,
            });
            if(menus && menu) {
                menus.push(menu);
            }
        }

        toast(i18n.t<string>('screens:autoAttendants.menuCreated'));
        yield put(actions.createMenu.success());

        action.payload.callback?.(response.data.i_menu);
    } catch (e: any) {
        let msg = i18n.t<string>('common:undefinedError');

        if (e.response !== undefined) {
            msg = e.response?.data?.faultstring;
        }

        showErrorToast(msg);
        yield put(actions.createMenu.failure(e));
    }
}

export const menusSaga = [
    takeLatest(actions.getMenuDetails.request, getMenuDetails),
    takeLatest(actions.getMenuPromptFile.request, getMenuPromptFile),
    takeLatest(actions.editMenu.request, editMenu),
    takeLatest(
        actions.uploadMenuTransitionPrompt.request,
        uploadMenuTransitionPrompt,
    ),
    takeLatest(actions.removeMenu.request, removeMenu),
    takeLatest(actions.createMenu.request, createNewMenu),
];
