import {AxiosResponse} from 'axios';
import qs from 'qs';
import toast from 'react-hot-toast';
import {put, takeLatest, select, delay, call} from 'redux-saga/effects';
import {ActionType} from 'typesafe-actions';
import {api} from '../../services/axios';
import {actions} from '../..';
import {
    Customer,
    DialingRule,
    CodecConverter,
} from '../../../services/endpoints';
import i18n from '../../../services/i18n';
import {compareObjectsAndReturnDifferencesInValues} from '../../../utils/compareObjects';
import JSONFormData from '../../../utils/JSONFormData';
import {showErrorToast} from '../../../utils/showErrorToast';
import {AccountMohListInfo, MohInfo} from '../../types/AccountMoh';
import {CustomerInfo} from '../../types/CustomerInfo';
import {ServiceFeatureName, ServiceFeature} from '../../types/ServiceFeature';
import {EditCallSettingsForm} from '../../../views/CallSettings/CallSettingsDetails';
import {DialingRuleInfo} from '../../types/DialingRule';
import {ReduxState} from '../../types';
import {CallBarringRule} from '../../types/CallBarring';
import {getDidNumbersForSelect} from '../didNumbers/saga';
import {getCallProcessingPolicyList} from "../extensions/callScreening/saga";
import { GetCallProcessingPolicyListEntity, GetCPConditionListResponsePayload, OperationMode } from '../../actions/extensions/payloads';
import { getCallProcessingOperationModeList } from '../extensions/extensions/saga';
import { ApiFile, ApiFileList } from '../../types/ApiFile';
import { ChangeForwardingModeState, ChangeForwardingModeStateItem, CPConditionInfo, CPConditionTimeWindowInfo } from '../../types/CallScreening';
import { TimeFiltersPageFilterType } from '../../../components/Forms/CallSettings/CallScreening/NewTimeFilterDialog.utils';
import { makePeriod } from '../../../utils/extensions/CallScreeningTimeWindowToPeriod';

export function* getCallSettingsDetails() {
    try {
        // call here
        yield getExtensionMohDetails();
        yield getMusicRingDetails();
        yield getCustomerInfo();
        yield getDialingRules();
        yield getCustomerDetailsForCallSettings();
        yield getCallBarringOptions();
        yield getDidNumbersForSelect();
        yield getCallScreeningTabData();
        yield put(actions.getCallSettingsDetails.success());
    } catch (err) {
        //@ts-ignore
        showErrorToast(err.response?.data?.faultstring);
        yield put(actions.getCallSettingsDetails.failure());
    }
}

function* getCustomerCallScreeningConditions (
    action: ActionType<typeof actions.getCustomerCallScreeningConditions.request>) 
{
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams(action.payload);

        const res: AxiosResponse<GetCPConditionListResponsePayload> = 
            yield api.post(Customer.GetCpConditionList, body);
        
        yield put(actions.getCustomerCallScreeningConditions.success(res.data));
    } catch (err) {
        //@ts-ignore
        showErrorToast(err.response?.data?.faultstring);
        yield put(actions.getCustomerCallScreeningConditions.failure());
    }
};

function* getCustomerResponseMessage(
    action: ActionType<typeof actions.getCustomerResponseMessage.request>)
{
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams(action.payload);
        const res: AxiosResponse<ApiFileList> = yield api.post(
            CodecConverter.GetFileList,
            body,
        );

        yield put(actions.getCustomerResponseMessage.success(res.data));
    } catch (err) {
        //@ts-ignore
        showErrorToast(err.response?.data?.faultstring);
        yield put(actions.getCustomerResponseMessage.failure());
    }
}

function* getCallScreeningTabData() {
    const { i_customer } = yield select((state) => state.generic.sessionData);

    yield call(
        getCustomerCallScreeningConditions,
        actions.getCustomerCallScreeningConditions.request({
            get_total: 1,
            i_customer: i_customer
        }),
    );

    yield call(
        getCallProcessingOperationModeList,
        actions.getCallProcessingOperationModeList.request({
            check_usage: 0,
            i_customer: i_customer
        })
    )

    yield call(
        getCustomerResponseMessage,
        actions.getCustomerResponseMessage.request({
            i_owner: i_customer,
            handler: 'response_message',
            owner_type: 'customer'
        }),
    );

    yield call(getCallProcessingPolicyList, actions.getCallProcessingPolicyList.request({
        with_rules: 1,
        i_customer: i_customer
    }));
}

export function* editCallSettings(
    action: ActionType<typeof actions.editCallSettings.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);

        const policies: GetCallProcessingPolicyListEntity[] | undefined = 
            yield select((state) => state.extensions.callProcessingPolicyList);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams(action.payload);

        const dataToSave = compareObjectsAndReturnDifferencesInValues(
            action.payload.initialValues,
            action.payload.changedValues,
        );

        yield editDialingRules(action, dataToSave);
        yield editCustomerServiceFeatures(action, dataToSave);
        yield updateCallBarringRules(action, dataToSave);

        for(const policy of action.payload.changedValues.policies) {
            if(policy) {
                //editPolicy
                const updObject = policies?.find(e => e.i_cp_policy === policy.i_cp_policy);
                yield call(editPolicy, actions.editPolicy.request({
                    object: updObject
                }));
            }
        }

        toast(i18n.t<string>('screens:callSettings.callSettingsEdited'));

        yield delay(1000);

        yield put(actions.editCallSettings.success());

        location?.replace(
            `?${qs.stringify({
                tab: action.payload.redirectTab,
            })}`,
        );
    } catch (err) {
        //@ts-ignore
        showErrorToast(err.response?.data?.faultstring);
        yield put(actions.editCallSettings.failure());
    }
}

export function* getExtensionMohDetails() {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        const mohListRes: AxiosResponse<{
            moh_list_info: AccountMohListInfo;
        }> = yield api.post(Customer.GetCustomerMohListInfo, body);

        const mohList: MohInfo[] =
            mohListRes?.data.moh_list_info?.aggregated_moh_list;

        yield put(
            actions.getCallSettingsMohDetails.success({
                items: mohList,
            }),
        );
    } catch (err) {
        //@ts-ignore
        showErrorToast(err.response?.data?.faultstring);
    }
}

export function* getMusicRingDetails() {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        const res: AxiosResponse<CustomerInfo> = yield api.post(
            Customer.GetCustomerInfo,
            body,
        );
        const serviceFeatures = res.data.customer_info
            ?.service_features as ServiceFeature[];

        const extToExtFeature = serviceFeatures?.find(
            (v: ServiceFeature) =>
                v.name === ServiceFeatureName.DistinctiveRingVPN,
        );

        const musicOnHoldFeature = serviceFeatures?.find(
            (v: ServiceFeature) => v.name === ServiceFeatureName.MusicOnHold,
        );
        if (musicOnHoldFeature || extToExtFeature) {
            yield put(
                actions.getMusicRingDetails.success({
                    onHoldMusicStatus:
                        musicOnHoldFeature &&
                        musicOnHoldFeature.flag_value === 'Y' &&
                        musicOnHoldFeature.effective_flag_value === 'Y',
                    extToExtStatus:
                        extToExtFeature &&
                        extToExtFeature.flag_value === 'Y' &&
                        extToExtFeature.effective_flag_value === 'Y',
                }),
            );
        }
    } catch (err) {
        //@ts-ignore
        showErrorToast(err.response?.data?.faultstring);
    }
}

export function* getDialingRules() {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    body.setParams({
        with_internal: '1',
        usage_type: 'D',
    });

    const res: AxiosResponse<{
        dialing_rules_list: DialingRuleInfo[];
    }> = yield api.post(DialingRule.GetiDialingRulesList, body);

    const customerServiceFeatures: ServiceFeature[] = yield select(
        (state: ReduxState) =>
            state.callSettings?.customerServiceFeatures || [],
    );

    const voiceDialingFeature = customerServiceFeatures?.find(
        (v) => v.name === ServiceFeatureName.VoiceDialing,
    ) as ServiceFeature;

    const dialId = voiceDialingFeature?.attributes.find(
        (v) => v.name === 'i_dial_rule',
    )?.values[0];

    if (
        dialId !== undefined &&
        !res.data.dialing_rules_list.find(
            (v) => v.i_dialing_rule === parseInt(dialId),
        )
    ) {
        body.setParams({
            i_dialing_rule: dialId,
        });

        const rule: AxiosResponse<{
            dialing_rule_info: DialingRuleInfo;
        }> = yield api.post(DialingRule.GetDialingRuleInfo, body);

        res.data.dialing_rules_list.push(rule.data.dialing_rule_info);
    }

    yield put(actions.getDialingRules.success(res.data.dialing_rules_list));
}

export function* getCustomerInfo() {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    const res: AxiosResponse<CustomerInfo> = yield api.post(
        Customer.GetCustomerInfo,
        body,
    );

    if (res.data.customer_info.service_features) {
        yield put(
            actions.getCustomerServiceFeatures.success(
                res.data.customer_info.service_features,
            ),
        );
    }
}

export function* getCustomerDetailsForCallSettings() {
    const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);

    const body = new JSONFormData(session_id, csrf_token);

    const res: AxiosResponse<CustomerInfo> = yield api.post(
        Customer.GetCustomerInfo,
        body,
    );
    const serviceFeatures = res.data.customer_info
        ?.service_features as ServiceFeature[];

    const callBarring = serviceFeatures?.find(
        (e: ServiceFeature) => e.name === ServiceFeatureName.CallBarring,
    );

    if (callBarring) {
        yield put(
            actions.getCustomerInfoCallBarring.success({
                callBarring: callBarring,
                customerInfo: res.data.customer_info,
            }),
        );
    }
}

export function* editDialingRules(
    action: ActionType<typeof actions.editCallSettings.request>,
    data: Partial<EditCallSettingsForm>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    if (data.rules) {
        for (let i = 0; i < data.rules.length; i++) {
            const comparedRule = compareObjectsAndReturnDifferencesInValues(
                action.payload.initialValues.rules[i],
                data.rules[i],
            );

            if (Object.keys(comparedRule).length) {
                body.setParams({
                    dialing_rule_info: {
                        i_owner: data.rules[i].i_owner,
                        name: data.rules[i].name,
                        i_dialing_rule: data.rules[i].i_dialing_rule,
                        dial_codes: data.rules[i].dial_codes,
                    },
                });
                yield api.post(DialingRule.UpdateDialingRule, body);
            }
        }
    }
}

export function* editCustomerServiceFeatures(
    action: ActionType<typeof actions.editCallSettings.request>,
    data: Partial<EditCallSettingsForm>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    const params: { service_features: ServiceFeature[] } = {
        service_features: [],
    };

    const customerServiceFeatures: ServiceFeature[] = yield select(
        (state) => state.callSettings.customerServiceFeatures,
    );

    if (data.selectedRuleId !== undefined) {
        const voiceDialingFeature = customerServiceFeatures?.find(
            (v) => v.name === ServiceFeatureName.VoiceDialing,
        ) as ServiceFeature;
        params.service_features.push({
            name: ServiceFeatureName.VoiceDialing,
            attributes: [
                {
                    effective_values: [data.selectedRuleId],
                    name: 'i_dial_rule',
                    values: [data.selectedRuleId],
                },
            ],
            effective_flag_value: voiceDialingFeature.effective_flag_value,
            flag_value: voiceDialingFeature.flag_value,
        });
    }

    if (data.callParkingStatus !== undefined) {
        params.service_features.push({
            name: ServiceFeatureName.CallParking,
            effective_flag_value: data.callParkingStatus ? 'Y' : 'N',
            flag_value: data.callParkingStatus ? 'Y' : 'N',
            attributes: [],
        });
    }

    if (data.pagingIntercomStatus !== undefined) {
        params.service_features.push({
            name: ServiceFeatureName.Paging,
            effective_flag_value: data.pagingIntercomStatus ? 'Y' : 'N',
            flag_value: data.pagingIntercomStatus ? 'Y' : 'N',
            attributes: [],
        });
    }

    if (data.groupPickupStatus !== undefined) {
        params.service_features.push({
            name: ServiceFeatureName.GroupPicking,
            effective_flag_value: data.groupPickupStatus ? 'Y' : 'N',
            flag_value: data.groupPickupStatus ? 'Y' : 'N',
            attributes: [],
        });
    }

    if (data.callSupervisionStatus !== undefined) {
        params.service_features.push({
            name: ServiceFeatureName.CallSupervision,
            effective_flag_value: data.callSupervisionStatus ? 'Y' : 'N',
            flag_value: data.callSupervisionStatus ? 'Y' : 'N',
            attributes: [],
        });
    }

    if (data.defaultCompanyNumber !== undefined) {
        params.service_features.push({
            name: ServiceFeatureName.Cli,
            effective_flag_value: data.defaultCompanyNumber.length ? 'Y' : 'N',
            flag_value: data.defaultCompanyNumber.length ? 'Y' : 'N',
            attributes: [
                {
                    name: 'centrex',
                    values: [data.defaultCompanyNumber],
                    effective_values: [data.selectedRuleId],
                },
            ],
        });
    }

    if (params.service_features.length > 0) {
        body.setParams({customer_info: params});
        yield api.post(Customer.UpdateCustomer, body);
    }
}

export function* getCallBarringOptions() {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);

        const body = new JSONFormData(session_id, csrf_token);

        const res: AxiosResponse<{
            options: CallBarringRule[];
        }> = yield api.post(Customer.GetCallBarringOptions, body);

        yield put(
            actions.getCallBarringOptions.success({
                callBarringOptions: res.data.options,
            }),
        );
    } catch (err) {
        yield put(actions.getCallBarringOptions.failure());
    }
}

export function* updateCallBarringRules(
    action: ActionType<typeof actions.editCallSettings.request>,
    data: Partial<EditCallSettingsForm>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const { mohDetails, onHoldMusicStatus } = yield select(
        (state) => state.callSettings,
    );
    const body = new JSONFormData(session_id, csrf_token);
    let item;
    if (data.selectedMohFile)
        item = mohDetails.items.find(
            (e: MohInfo) => e.name === data.selectedMohFile,
        );

    if (
        data.onHoldMusicSwitcher !== undefined ||
        (onHoldMusicStatus && data.selectedMohFile !== undefined)
    ) {
        const status =
            data.onHoldMusicSwitcher !== undefined
                ? data.onHoldMusicSwitcher
                    ? 'Y'
                    : 'N'
                : onHoldMusicStatus
                    ? 'Y'
                    : 'N';
        if (status === 'Y') {
            body.setParams({
                service_features: [
                    {
                        name: ServiceFeatureName.MusicOnHold,
                        flag_value: status,
                        effective_flag_value: status,
                        attributes: [
                            {
                                effective_values: [item.i_moh],
                                name: 'i_moh',
                                values: [item.i_moh],
                            },
                        ],
                    },
                ],
            });
        } else {
            body.setParams({
                service_features: [
                    {
                        name: ServiceFeatureName.MusicOnHold,
                        flag_value: status,
                        effective_flag_value: status,
                    },
                ],
            });
        }
        yield api.post(Customer.UpdateCustomerServiceFeature, body);
    }

    if (data.extToExtSwitcher !== undefined) {
        const status = data.extToExtSwitcher ? 'Y' : 'N';
        body.setParams({
            service_features: [
                {
                    name: ServiceFeatureName.DistinctiveRingVPN,
                    flag_value: status,
                    effective_flag_value: status,
                },
            ],
        });
        yield api.post(Customer.UpdateCustomerServiceFeature, body);
    }

    if (
        data.individualRules !== undefined ||
        data.callBarringStatus !== undefined
    ) {
        const individualRules =
            data.individualRules ??
            action.payload.initialValues.individualRules;
        const callBarringStatus =
            data.callBarringStatus ??
            action.payload.initialValues.callBarringStatus;

        body.setParams({
            service_features: [
                {
                    name: ServiceFeatureName.CallBarring,
                    flag_value: callBarringStatus
                        ? individualRules
                            ? '~'
                            : 'Y'
                        : 'N',
                    effective_flag_value: callBarringStatus
                        ? individualRules
                            ? ''
                            : 'Y'
                        : 'N',
                },
            ],
        });
        yield api.post(Customer.UpdateCustomerServiceFeature, body);
    }

    if (data.callBarringItems !== undefined) {
        body.setParams({
            options: data.callBarringItems,
        });

        yield api.post(Customer.UpdateCallBarringOptions, body);
    }
}

export function* uploadMusicFile(
    action: ActionType<typeof actions.uploadMusicFile.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            handler: 'moh',
            file_name: action.payload.name,
        });

        body.append('upload_file', action.payload.file);

        yield api.post(CodecConverter.AddFile, body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (action.payload.callback) {
            action.payload?.callback();
            yield getExtensionMohDetails();
        }

        toast(i18n.t<string>('common:fileUploaded'));
        yield put(actions.uploadRingbackTone.success());
    } catch (err) {
        action.payload.errorCallback && action.payload.errorCallback();
        //@ts-ignore
        yield put(actions.uploadRingbackTone.failure(err));
    }
}

export function* addNewPolicy(
    action: ActionType<typeof actions.addNewPolicy.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);

        const { callProcessingPolicyList } =  yield select((state) => 
            state.extensions);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            cp_policy_info: action.payload.object
        });

        const res: AxiosResponse<{
            i_cp_policy: number;
        }> = yield api.post(Customer.AddCallProcessingPolicy, body);

        const newValuesList = (callProcessingPolicyList || []) as GetCallProcessingPolicyListEntity[];
        newValuesList.push({
            rule_list: [],
            name: action.payload.object.name,
            i_customer: action.payload.object.i_customer || 0,
            i_cp_policy: res?.data?.i_cp_policy || action.payload.object.i_cp_policy || 0
        });
        
        action.payload?.callback?.();
        toast(i18n.t<string>('screens:callSettings.addNewPolicySuccess'));

        yield put(actions.addNewPolicy.success(newValuesList));
    } catch (err) {
        //@ts-ignore
        yield put(actions.addNewPolicy.failure(err?.response?.data));
    }
}

export function* deletePolicy(
    action: ActionType<typeof actions.deletePolicy.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            i_cp_policy: action.payload.i_cp_policy
        });

        yield api.post(Customer.DeleteCallProcessingPolicy, body);

        action.payload?.callback?.();
        toast(i18n.t<string>('screens:callSettings.deletePolicySuccess'));

        yield put(actions.deletePolicy.success(action.payload));
    } catch (err) {
        //@ts-ignore
        yield put(actions.deletePolicy.failure(err?.response?.data));
    }
}

export function* editPolicy(
    action: ActionType<typeof actions.editPolicy.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);

        const { callProcessingPolicyList } =  yield select((state) => 
            state.extensions);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            cp_policy_info: action.payload.object
        });

        yield api.post(Customer.EditCallProcessingPolicy, body);

        const newValuesList = (callProcessingPolicyList || []) as GetCallProcessingPolicyListEntity[];
        const objectToUpd = newValuesList.find(e => e.i_cp_policy === action.payload.object?.i_cp_policy)
            || newValuesList.find(e => e.name === action.payload.object?.name);
        if(objectToUpd) {
            const indx = newValuesList.indexOf(objectToUpd);
            //@ts-ignore
            newValuesList[indx] = action.payload.object;
        }
        
        action.payload.callback?.();
        if(action.payload.successToastText) {
            toast(action.payload.successToastText);
        }

        yield put(actions.editPolicy.success(newValuesList));
    } catch (err) {
        //@ts-ignore
        yield put(actions.editPolicy.failure(err?.response?.data));
    }
}

export function* updateRulesOrder(
    action: ActionType<typeof actions.updateRulesOrder.request>,
) {
    const policies: GetCallProcessingPolicyListEntity[] = yield select((state) => 
        state.extensions.callProcessingPolicyList || []);
    const result = policies.map(e => {
            if(e.i_cp_policy === action.payload.i_cp_policy) {
                return action.payload;
            }
            return e;
    });
    yield put(actions.updateRulesOrder.success(result));
}

export function* addMode(
    action: ActionType<typeof actions.addMode.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        
        const modes: OperationMode[] = yield select((state) => 
            state.extensions.callProcessingOperationModeList || []);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            operation_mode_info: action.payload.object
        });

        const res: AxiosResponse<{i_operation_mode: number}> = yield api.post(Customer.AddCallProcessingMode, body);

        toast(i18n.t<string>('screens:callSettings.addModeSuccess'));

        modes.push({
            ...action.payload.object,
            i_operation_mode: res.data?.i_operation_mode || 0
        });

        action.payload?.callback?.();
        yield put(actions.addMode.success([...modes]));
    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data;
        showErrorToast(error?.faultstring);
        yield put(actions.addMode.failure(error));
    }
}

export function* editMode(
    action: ActionType<typeof actions.editMode.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        
        const modes: OperationMode[] = yield select((state) => 
            state.extensions.callProcessingOperationModeList || []);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            operation_mode_info: action.payload.object
        });

        yield api.post(Customer.EditCallProcessingMode, body);

        toast(i18n.t<string>('screens:callSettings.editModeSuccess'));

        const obj = modes.find(e => e.i_operation_mode === action.payload.object.i_operation_mode);
        const newObj = {
            ...obj,
            ...action.payload.object
        }
        //@ts-ignore
        const indx = modes.indexOf(obj);
        modes[indx] = newObj;

        action.payload?.callback?.();
        yield put(actions.editMode.success([...modes]));
    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data;
        showErrorToast(error?.faultstring);
        yield put(actions.editMode.failure(error));
    }
}

export function* deleteMode(
    action: ActionType<typeof actions.deleteMode.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        
        const modes: OperationMode[] = yield select((state) => 
            state.extensions.callProcessingOperationModeList || []);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams(action.payload);

        yield api.post(Customer.DeleteCallProcessingMode, body);

        action.payload?.callback?.();
        toast(i18n.t<string>('screens:callSettings.deleteModeSuccess'));

        const leftModes = modes.filter(e => e.i_operation_mode !== action.payload.i_operation_mode);

        yield put(actions.deleteMode.success([...leftModes]));
    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data;
        showErrorToast(error?.faultstring);
        yield put(actions.deleteMode.failure(error));
    }
}

export function* addCpCondition(
    action: ActionType<typeof actions.addCpCondition.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        
        const timeFilters: CPConditionInfo[] = yield select((state) => 
            state.callSettings.cpConditionsList?.cp_condition_list || []);
            
        const body = new JSONFormData(session_id, csrf_token);

        const interval = makePeriod({
            wholeDay: true,
            startTime: '',
            endTime: '',
            daysOfMonth: '',
            days: [],
            months: []
        });

        const postObject = {
            ...action.payload.object,
            time_window: action.payload.object.type === TimeFiltersPageFilterType.TimeWindow.toString()
            ? {
                period: interval,
                description: interval,
                definition_list: []
            } as CPConditionTimeWindowInfo
            : undefined
        };

        body.setParams({
            cp_condition_info: postObject
        });

        const res: AxiosResponse<{i_cp_condition: number}> = yield api.post(Customer.AddCpCondition, body);

        timeFilters.push({
            ...postObject,
            i_cp_condition: res.data.i_cp_condition
        });

        toast(i18n.t<string>('screens:callSettings.callSettingsEdited'));

        action.payload?.callback?.();
        yield put(actions.addCpCondition.success([...timeFilters]));
    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data;
        showErrorToast(error?.faultstring);
        yield put(actions.addCpCondition.failure(error));
    }
}

export function* editCpCondition(
    action: ActionType<typeof actions.editCpCondition.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        
        const timeFilters: CPConditionInfo[] = yield select((state) => 
            state.callSettings.cpConditionsList?.cp_condition_list || []);
            
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            cp_condition_info: action.payload.object
        });

        yield api.post(Customer.EditCpCondition, body);

        const indx = timeFilters.findIndex(e => e.i_cp_condition === action.payload.object.i_cp_condition);
        timeFilters[indx] = {
            ...timeFilters[indx],
            ...action.payload.object
        };

        toast(i18n.t<string>('screens:callSettings.callSettingsEdited'));

        action.payload?.callback?.();
        yield put(actions.editCpCondition.success([...timeFilters]));
    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data;
        showErrorToast(error?.faultstring);
        yield put(actions.editCpCondition.failure(error));
    }
}

export function* deleteCpCondition(
    action: ActionType<typeof actions.deleteCpCondition.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        
        const timeFilters: CPConditionInfo[] = yield select((state) => 
            state.callSettings.cpConditionsList?.cp_condition_list || []);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams(action.payload);

        yield api.post(Customer.DeleteCpCondition, body);

        action.payload?.callback?.();
        toast(i18n.t<string>('screens:callSettings.callSettingsEdited'));

        const leftFilters = timeFilters.filter(e => e.i_cp_condition !== action.payload.i_cp_condition);

        yield put(actions.deleteCpCondition.success([...leftFilters]));
    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data;
        showErrorToast(error?.faultstring);
        yield put(actions.deleteCpCondition.failure(error));
    }
}

export function* addCustomerResponseMessage(
    action: ActionType<typeof actions.addCustomerResponseMessage.request>,
) {
    try {
        const files: ApiFile[] = yield select(
            (state) => state.callSettings?.customerResponseMessages?.file_list || []
        );
        
        const { i_customer } = yield select((state) => state.generic.sessionData);

        const { session_id, csrf_token } = yield select((state) => state.auth);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            handler: 'response_message',
            file_name: action.payload.object.file_name,
            object: 'customer',
            i_object: i_customer,
            transitional: action.payload.object.transitional
        });

        //@ts-ignore
        body.append('upload_file', action.payload.object.file);

        const res: AxiosResponse<{id: number}> = yield api.post(CodecConverter.AddFile, body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        action.payload?.callback?.();
        toast(i18n.t<string>('screens:callSettings.callSettingsEdited'));
        
        files.push({
            id: res.data.id,
            name: action.payload.object.file_name,
            transitional: action.payload.object.transitional,
            own: 1,
            status: "FINISHED"
        } as ApiFile);
        
        yield put(actions.addCustomerResponseMessage.success({file_list: [...files]} as ApiFileList));
    } catch (err) {
        //@ts-ignore
        yield put(actions.addCustomerResponseMessage.failure(err));
    }
}

export function* editCustomerResponseMessage(
    action: ActionType<typeof actions.editCustomerResponseMessage.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        
        const files: ApiFile[] = yield select(
            (state) => state.callSettings?.customerResponseMessages?.file_list || []
        );

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            id: action.payload.object.i_object,
            transitional: action.payload.object.transitional,
            file_name: action.payload.object.file_name
        });

        yield api.post(CodecConverter.EditFile, body);

        action.payload?.callback?.();
        toast(i18n.t<string>('screens:callSettings.callSettingsEdited'));

        const indx = files.findIndex(e => e.id === action.payload.object.i_object);
        files[indx] = {
            ...files[indx],
            name: action.payload.object.file_name,
            transitional: action.payload.object.transitional
        };

        yield put(actions.editCustomerResponseMessage.success({file_list: [...files]} as ApiFileList));
    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data;
        showErrorToast(error?.faultstring);
        yield put(actions.editCustomerResponseMessage.failure(error));
    }
}

export function* deleteCustomerResponseMessage(
    action: ActionType<typeof actions.deleteCustomerResponseMessage.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        
        const files: ApiFile[] = yield select(
            (state) => state.callSettings?.customerResponseMessages?.file_list || []
        );

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams(action.payload);

        yield api.post(CodecConverter.DeleteFile, body);

        action.payload?.callback?.();
        toast(i18n.t<string>('screens:callSettings.callSettingsEdited'));

        const leftFiles = files.filter(e => e.id !== action.payload.id);

        yield put(actions.deleteCustomerResponseMessage.success({file_list: leftFiles} as ApiFileList));
    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data;
        showErrorToast(error?.faultstring);
        yield put(actions.deleteCustomerResponseMessage.failure(error));
    }
}

export function* updateChangeForwardingModeState(
    action: ActionType<typeof actions.updateChangeForwardingModeState.request>,
) {
    const stateValue: ChangeForwardingModeState = yield select(
        (state: ReduxState) => state.extensions?.changeForwardingModeState
    );

    if(!stateValue?.stateValue?.length)
    {
        yield put(actions.updateChangeForwardingModeState.success({stateValue: [action.payload]}));
    } else {
        const extType = stateValue.stateValue.find(e => e.flag === action.payload.flag);
        const items: ChangeForwardingModeStateItem[] = [];
        for(const item of stateValue.stateValue) {
            if(item != extType) {
                items.push(item);
            }
        }
        items.push(action.payload);
        yield put(actions.updateChangeForwardingModeState.success({stateValue: items}));
    }
}

export const callSettingsSaga = [
    takeLatest(actions.getCallSettingsDetails.request, getCallSettingsDetails),
    takeLatest(actions.editCallSettings.request, editCallSettings),
    takeLatest(actions.uploadMusicFile.request, uploadMusicFile),
    takeLatest(actions.getCustomerCallScreeningConditions.request, getCustomerCallScreeningConditions),
    takeLatest(actions.getCustomerResponseMessage.request, getCustomerResponseMessage),
    takeLatest(actions.addNewPolicy.request, addNewPolicy),
    takeLatest(actions.deletePolicy.request, deletePolicy),
    takeLatest(actions.editPolicy.request, editPolicy),
    takeLatest(actions.updateRulesOrder.request, updateRulesOrder),
    takeLatest(actions.addMode.request, addMode),
    takeLatest(actions.editMode.request, editMode),
    takeLatest(actions.deleteMode.request, deleteMode),
    takeLatest(actions.addCpCondition.request, addCpCondition),
    takeLatest(actions.editCpCondition.request, editCpCondition),
    takeLatest(actions.deleteCpCondition.request, deleteCpCondition),
    takeLatest(actions.addCustomerResponseMessage.request, addCustomerResponseMessage),
    takeLatest(actions.editCustomerResponseMessage.request, editCustomerResponseMessage),
    takeLatest(actions.deleteCustomerResponseMessage.request, deleteCustomerResponseMessage),
    takeLatest(actions.updateChangeForwardingModeState.request, updateChangeForwardingModeState),
];