import CustomizedTooltip from '../Tooltip/Tooltip';
import { Badge } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import { TimeWindowIntervalsDetails } from '../../store/types/CallScreening';

const useStyles = makeStyles(() => ({
    wraper: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        maxWidth: '95%',
    },

    badge: {
        '& > span.MuiBadge-badge': {
            backgroundColor: Colors.Gray4,
            color: Colors.Gray5,
        },
    },

    badgeTooltip: {
        minWidth: 15,
    },
}));

type TimeFilterCellProps = {
    showOnlyPolicy?: boolean;
    timeFilters: TimeWindowIntervalsDetails[];
    text: string;
};

const TimeFilterCell: React.VFC<TimeFilterCellProps> = ({
    timeFilters,
    showOnlyPolicy,
    text,
}) => {
    const classes = useStyles();

    const badgeTooltipContent =
        timeFilters.length > 1
            ? timeFilters.map((item, key) => (
                  <span key={key}>{item.description}</span>
              ))
            : '';

    return !showOnlyPolicy ? (
        timeFilters.length > 1 ? (
            <div className={classes.wraper}>
                <span>{text}</span>
                <CustomizedTooltip
                    title={badgeTooltipContent}
                    dataQa={'tooltip-time-filters-list'}
                    interactive={true}
                    copy={false}
                    className={classes?.badgeTooltip}
                    above
                >
                    <Badge
                        badgeContent={
                            <span style={{ whiteSpace: 'nowrap' }}>
                                {timeFilters.length}
                            </span>
                        }
                        className={classes?.badge}
                        data-qa={'time-filters-counter'}
                    />
                </CustomizedTooltip>
            </div>
        ) : (
            <>{text}</>
        )
    ) : null;
};

export default TimeFilterCell;
